import React, { useEffect, useState } from 'react'
import { Outlet } from "react-router-dom";

import Header from '../components/header';
import Footer from '../components/footer';

function LoginSignUp() {

  return (
    <div>
      <Header />
      <div className="container" style={{ paddingBottom: 60 }}>
        <div className="row">
          <div className="col-sm-4 col-sm-offset-1">
            <div className="login-form">{/*login form*/}
              <h2>Connectez-vous à votre compte</h2>
              <form action="#">
                <input type="email" placeholder="Adresse email" required />
                <input type="password" placeholder="Mot de passe" required />
                <span>
                  <input type="checkbox" className="checkbox" />
                  Rester connecter
                </span>
                <button type="submit" className="btn btn-default">Se connecter</button>
              </form>
            </div>{/*/login form*/}
          </div>
          <div className="col-sm-1">
            <h2 className="or">OU</h2>
          </div>
          <div className="col-sm-4">
            <div className="signup-form">{/*sign up form*/}
              <h2>Nouvel utilisateur, Incrivez-vous!</h2>
              <form action="#">
                <input type="text" placeholder="Nom" />
                <input type="text" placeholder="Prénom(s)" />
                <input type="email" placeholder="Adresse email" />
                <input type="text" placeholder="Adresse" />
                <input type="text" placeholder="BP" />
                <h5>Photocopie de carte d'identité ou de passeport</h5>
                <input type="file" />
                <h5>Justificatif de domicile</h5>
                <input type="file" />
                <h5>RIB</h5>
                <input type="file" />
                <input type="password" placeholder="Mot de passe" />
                <input type="password" placeholder="Confirmer votre mot de passe" />
                <button type="submit" className="btn btn-default">S'inscrire</button>
              </form>
            </div>{/*/sign up form*/}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default LoginSignUp
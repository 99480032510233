import React, { useEffect, useState } from 'react'
import { Outlet } from "react-router-dom";

import Header from '../components/header';
import CustomButton from '../components/customButton';
import shop1__img from '../assets/../assets/images/Shop__1.png'
import CustomCard from '../components/customCard';
import Footer from '../components/footer';
import { Placeholder } from 'react-bootstrap';

function ContactUs() {

  useEffect(() => {
    document.title = 'Contact-Us';
  }, []);
  return (
    <div>
      <Header />
      <div id="contact-page" className="container">
        <div className="bg"  >
          <div className="row">
            <div className="col-sm-12">
              <h2 className="title text-center">NOUS <strong>CONTACTER</strong></h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8" >
              <div className="contact-form">
                <h2 className="title text-center">Ecrivez nous</h2>
                <div className="status alert alert-success" style={{ display: 'none' }} />
                <form id="main-contact-form" className="contact-form row" name="contact-form" method="post">
                  <div className="form-group col-md-6">
                    <input type="text" name="name" className="form-control" required placeholder="Nom" />
                  </div>
                  <div className="form-group col-md-6">
                    <input type="email" name="email" className="form-control" required placeholder="Adresse email" />
                  </div>
                  <div className="form-group col-md-12">
                    <input type="text" name="subject" className="form-control" required placeholder="Objet" />
                  </div>
                  <div className="form-group col-md-12">
                    <textarea name="message" id="message" required className="form-control" rows={8} placeholder="Entrez votre message ici" defaultValue={""} />
                  </div>
                  <div className="form-group col-md-12">
                    <input type="submit" name="submit" className="btn btn-primary pull-right" defaultValue="Submit" />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="contact-info">
                <h2 className="title text-center">Contact Info</h2>
                <address>
                  <p>L.M.D-Encheres</p>
                  <p>935 W. Webster Ave New Streets Chicago, IL 60614, NY</p>
                  <p>Newyork USA</p>
                  <p>Mobile: +33 xxxxxxxx</p>
                  <p>Fax: +44 xxxxxxxx</p>
                  <p>Email: info@test.com</p>
                </address>
                <div className="social-networks">
                  <h2 className="title text-center">Réseau social</h2>
                  <ul>
                    <li>
                      <a href="#"><i className="fa fa-facebook" /></a>
                    </li>
                    <li>
                      <a href="#"><i className="fa fa-twitter" /></a>
                    </li>
                    <li>
                      <a href="#"><i className="fa fa-google-plus" /></a>
                    </li>
                    <li>
                      <a href="#"><i className="fa fa-youtube" /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ContactUs


import React, { useEffect, useState } from 'react'
import { Outlet } from "react-router-dom";

import Header from '../components/header';
import CustomButton from '../components/customButton';
import CustomCard from '../components/customCard';
import Footer from '../components/footer';
import { Placeholder } from 'react-bootstrap';

function ProductDetails() {

  useEffect(() => {
    document.title = 'Product Details';
  }, []);
  return (
    <div>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-9 padding-right">
              <div className="product-details">{/*product-details*/}
                <div className="col-sm-5">
                  <div className="view-product">
                    <img src={require("../assets/images/product-details/1.jpg")} alt="" />
                  </div>
                  <div id="similar-product" className="carousel slide" data-ride="carousel">
                    {/* Wrapper for slides */}
                    <div className="carousel-inner">
                      <div className="item active">
                        <a href="#"><img src="images/product-details/similar1.jpg" alt="" /></a>
                        <a href="#"><img src="images/product-details/similar2.jpg" alt="" /></a>
                        <a href="#"><img src="images/product-details/similar3.jpg" alt="" /></a>
                      </div>
                      <div className="item">
                        <a href="#"><img src="images/product-details/similar1.jpg" alt="" /></a>
                        <a href="#"><img src="images/product-details/similar2.jpg" alt="" /></a>
                        <a href="#"><img src="images/product-details/similar3.jpg" alt="" /></a>
                      </div>
                      <div className="item">
                        <a href="#"><img src="images/product-details/similar1.jpg" alt="" /></a>
                        <a href="#"><img src="images/product-details/similar2.jpg" alt="" /></a>
                        <a href="#"><img src="images/product-details/similar3.jpg" alt="" /></a>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-sm-7">
                  <div className="product-information">{/*/product-information*/}
                    <img src="images/product-details/new.jpg" className="newarrival" alt="" />
                    <h2>Anne Klein Sleeveless Colorblock Scuba</h2>
                    <p>Web ID: 1089772</p>
                    <img src="images/product-details/rating.png" alt="" />
                    <span>
                      <span>US $59</span>
                      <label>Quantity:</label>
                      <input type="text" defaultValue={3} />
                      <button type="button" className="btn btn-fefault cart">
                        <i className="fa fa-shopping-cart" />
                        Add to cart
                      </button>
                    </span>
                    <p><b>Availability:</b> In Stock</p>
                    <p><b>Condition:</b> New</p>
                    <p><b>Brand:</b> E-SHOPPER</p>
                    <a href="#"><img src="images/product-details/share.png" className="share img-responsive" alt="" /></a>
                  </div>
                </div>
              </div>
              <div className="category-tab shop-details-tab">{/*category-tab*/}
                <div className="col-sm-12">
                  <ul className="nav nav-tabs">
                    <li className="active"><a href="#reviews" data-toggle="tab">Détails</a></li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div className="tab-pane fade" id="companyprofile">
                    <div className="col-sm-3">
                      <div className="product-image-wrapper">
                        <div className="single-products">
                          <div className="productinfo text-center">
                            <img src="images/home/gallery1.jpg" alt="" />
                            <h2>$56</h2>
                            <p>Easy Polo Black Edition</p>
                            <button type="button" className="btn btn-default add-to-cart"><i className="fa fa-shopping-cart" />Add to cart</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="product-image-wrapper">
                        <div className="single-products">
                          <div className="productinfo text-center">
                            <img src="images/home/gallery3.jpg" alt="" />
                            <h2>$56</h2>
                            <p>Easy Polo Black Edition</p>
                            <button type="button" className="btn btn-default add-to-cart"><i className="fa fa-shopping-cart" />Add to cart</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade active in" id="reviews">
                    <div className="col-sm-12">
                      <ul>
                        <li><a href="#"><i className="fa fa-user" />EUGEN</a></li>
                        <li><a href="#"><i className="fa fa-clock-o" />12:41 PM</a></li>
                        <li><a href="#"><i className="fa fa-calendar-o" />31 DEC 2014</a></li>
                      </ul>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio iste cumque quos aperiam quod, dolor suscipit quo voluptates tenetur modi consectetur dolore voluptatum et voluptatibus aspernatur a numquam reprehenderit adipisci? Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad blanditiis consectetur aliquid fugit nam. Inventore, placeat tempora aut molestias deleniti et possimus eius ex, tenetur sapiente aspernatur. Soluta, fugit eius!
                        Asperiores laborum vero consequatur tenetur aperiam obcaecati atque harum fuga id a aspernatur eaque autem repellat quo corporis culpa animi, itaque dolor temporibus consequuntur deleniti architecto porro dolores voluptas. Consequatur!
                        Illum ullam dignissimos at natus adipisci voluptatibus nam, odio cumque aut assumenda doloremque quia amet esse corporis necessitatibus accusamus accusantium quibusdam temporibus nemo quidem dolore ab! Labore magnam quaerat sunt?
                        Nihil alias mollitia perferendis fugiat! Distinctio ipsa et sunt impedit laborum officia amet, voluptatem doloremque error magni vero minus inventore omnis, molestias esse obcaecati cupiditate? Consectetur accusamus praesentium ducimus dolor.
                        Veritatis minima ut repellat officiis fugit distinctio iure provident, omnis eligendi harum nulla minus doloribus vitae cumque hic voluptas architecto, sapiente ea natus blanditiis nam et perspiciatis. Nobis, consequuntur ad?
                        Culpa tempore commodi nostrum non aperiam deserunt. Officia molestias quam deleniti inventore sed distinctio, totam tempore. Molestiae cumque quod voluptatibus a, id at quisquam explicabo culpa! Aperiam doloremque excepturi obcaecati?
                        Ea sunt placeat quidem sed officia, distinctio voluptates ab delectus officiis nemo, alias ullam eum. Pariatur sed aut expedita voluptatem odit fuga provident voluptatibus repudiandae, deleniti maxime, asperiores minus nesciunt?
                        Totam illo magni distinctio qui doloribus ipsa culpa architecto ex sequi quidem atque, maiores porro earum iure nemo ut recusandae officiis a repudiandae laboriosam dolor nobis eligendi deleniti tempore! Doloribus!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default ProductDetails


import React, { useEffect, useState } from 'react'
import { Outlet } from "react-router-dom";

import Header from '../components/header';
import CustomButton from '../components/customButton';
import shop1__img from '../assets/../assets/images/Shop__1.png'
import CustomCard from '../components/customCard';
import Footer from '../components/footer';
import { Placeholder } from 'react-bootstrap';

function Root() {
  const [data, setData] = useState([{
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'

  }, {
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'

  }, {
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'

  }
    , {
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'

  }
    , {
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'

  }
    , {
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'

  }
    , {
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'

  }
    , {
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'

  }
    , {
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'
  }
    , {
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'
  }
    , {
    "id": 1,
    "title": "iPhone 9",
    "description": "An apple mobile which is nothing like apple",
    "price": 549,
    "discountPercentage": 12.96,
    "rating": 4.69,
    "stock": 94,
    "brand": "Apple",
    "category": "smartphones",
    "thumbnail": "...",
    "images": 'https://www.apple.com/newsroom/images/product/iphone/standard/Apple_iphone_11-rosette-family-lineup-091019_big.jpg.large.jpg'
  }]);
  useEffect(() => {
    document.title = 'Title';
  }, []);
  return (
    <div>
      <Header />
      <section id="slider">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="carousel slide slider-carousel" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="item active">
                    <div className="col-sm-6">
                      <h1><span>MEE </span> MARKET</h1>
                      <h2>Bienvenue sur notre nouvelle plateforme d'achat en ligne</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                      <button type="button" className="btn btn-primary">Commencer maintenant</button>
                      <br/>
                    </div>
                    <div className="col-sm-6">
                      <img src={require('../assets/images/mee/mee-alimentation-generale-livraison.jpg')} className="girl img-responsive" alt="" />
                       </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="left-sidebar">
                <h2>Catégories</h2>
                <div className="panel-group category-products" id="accordian">{/*category-productsr*/}
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h4 className="panel-title"><a href="#">Kids</a></h4>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h4 className="panel-title"><a href="#">Fashion</a></h4>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h4 className="panel-title"><a href="#">Households</a></h4>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h4 className="panel-title"><a href="#">Interiors</a></h4>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h4 className="panel-title"><a href="#">Clothing</a></h4>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h4 className="panel-title"><a href="#">Bags</a></h4>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h4 className="panel-title"><a href="#">Shoes</a></h4>
                    </div>
                  </div>
                </div>{/*/category-products*/}
                <div className="brands_products">{/*brands_products*/}
                  <h2>MARQUES</h2>
                  <div className="brands-name">
                    <ul className="nav nav-pills nav-stacked">
                      <li><a href="#"> <span className="pull-right">(50)</span>Nike</a></li>
                      <li><a href="#"> <span className="pull-right">(56)</span>Addias Erde</a></li>
                      <li><a href="#"> <span className="pull-right">(27)</span>Puma</a></li>
                      <li><a href="#"> <span className="pull-right">(32)</span>GUCCI</a></li>
                      <li><a href="#"> <span className="pull-right">(5)</span>TOMMY</a></li>
                      <li><a href="#"> <span className="pull-right">(9)</span>ZARA</a></li>
                    </ul>
                  </div>
                </div>{/*/brands_products*/}
                <div className="price-range">{/*price-range*/}
                  <h2>INTERVAL DE PRIX</h2>
                  <div className="well text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <input type="text" style={{ width: 100, paddingRight: 50, margin: 10 }} placeholder='Min' /><b className="pull-left">€</b>
                    <input type="text" style={{ width: 100, margin: 10 }} placeholder="Max" /> <b className="pull-right">€</b>

                  </div>
                </div>{/*/price-range*/}
                <div className="shipping text-center">{/*shipping*/}
                  <img src={require("../assets/images/mee/mee-eau.jpg")} style={{ width: '100%', height: '100%' }} alt="" />
                </div>{/*/shipping*/}
                <div className="shipping text-center">{/*shipping*/}
                  <img src={require("../assets/images/mee/mee-alimentation-generale-livraison.jpg")} style={{ width: '100%', height: '100%' }} alt="" />
                </div>{/*/shipping*/}
              </div>
            </div>

            {/*Mouveautes*/}
            <div className="col-sm-9 padding-right">
              <div className="features_items">{/*features_items*/}
                <h2 className="title text-center">Nouveautes</h2>
                {
                  data.map((value, index) =>
                    <CustomCard data={value} />
                  )
                }

              </div>{/*features_items*/}

              <div className="category-tab">{/*category-tab*/}
                <div className="tab-content">
                  <div className="tab-pane fade active in" id="tshirt">



                  </div>

                  <div className="tab-pane fade" id="kids">


                  </div>
                </div>
              </div>{/*/category-tab*/}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Root
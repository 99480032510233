import { useRouteError } from "react-router-dom";

export default function ProductCard(props: any) {

    return (
        <div className="col-sm-4">
                <div className="product-image-wrapper">
                  <div className="single-products">
                    <div className="productinfo text-center">
                      <img src={props.data.images} alt="" />
                      <h2>{props.data.price}€</h2>
                      <p>{props.data.title}</p>
                      <a href="#" className="btn btn-default add-to-cart"><i className="fa fa-shopping-cart" />Ajouter au panier</a>
                    </div>
                    <div className="product-overlay">
                      <div className="overlay-content">
                        <h2>{props.data.price}€</h2>
                        <p>{props.data.title}</p>
                        <a href="#" className="btn btn-default add-to-cart"><i className="fa fa-shopping-cart" />Ajouter au panier</a>
                      </div>
                    </div>
                  </div>
                  <div className="choose">
                    <ul className="nav nav-pills nav-justified">
                      <li><a href="#"><i className="fa fa-plus-square" />Ajouter à la liste des souhaits</a></li>
                      <li><a href="#"><i className="fa fa-plus-square" />Plus de détails</a></li>
                    </ul>
                  </div>
                </div>
              </div>
    );
}
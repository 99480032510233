import { useRouteError } from "react-router-dom";

export default function Footer(props: any) {

    return (
        <footer id="footer">{/*Footer*/}
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="companyinfo">
                                <h2><span>MEE </span> MARKET</h2>
                                <p>Nukafu, Rue des Amandiers </p>
                                <p>Lomé, TOGO </p>
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="col-sm-3">
                                <div className="video-gallery text-center">
                                    <a href="#">
                                        <div className="iframe-img">
                                            <img src="../assets/images/home/iframe1.png" alt="" />
                                        </div>
                                        <div className="overlay-icon">
                                            <i className="fa fa-play-circle-o" />
                                        </div>
                                    </a>
                                    <p>Circle of Hands</p>
                                    <h2>24 DEC 2014</h2>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="video-gallery text-center">
                                    <a href="#">
                                        <div className="iframe-img">
                                            <img src="../assets/images/home/iframe2.png" alt="" />
                                        </div>
                                        <div className="overlay-icon">
                                            <i className="fa fa-play-circle-o" />
                                        </div>
                                    </a>
                                    <p>Circle of Hands</p>
                                    <h2>24 DEC 2014</h2>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="video-gallery text-center">
                                    <a href="#">
                                        <div className="iframe-img">
                                            <img src="../assets/images/home/iframe3.png" alt="" />
                                        </div>
                                        <div className="overlay-icon">
                                            <i className="fa fa-play-circle-o" />
                                        </div>
                                    </a>
                                    <p>Circle of Hands</p>
                                    <h2>24 DEC 2014</h2>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="video-gallery text-center">
                                    <a href="#">
                                        <div className="iframe-img">
                                            <img src="../assets/images/home/iframe4.png" alt="" />
                                        </div>
                                        <div className="overlay-icon">
                                            <i className="fa fa-play-circle-o" />
                                        </div>
                                    </a>
                                    <p>Circle of Hands</p>
                                    <h2>24 DEC 2014</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="address">
                                <img src="../assets/images/home/map.png" alt="" />
                                <p>505 S Atlantic Ave Virginia Beach, VA(Virginia)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-widget">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="single-widget">
                                <h2>Besoin d'aide</h2>
                                <p>Lundi: 9:00 - 20:00</p>
                                <p>Mardi: 9:00 - 20:00</p>
                                <p>Mercredi: 9:00 - 20:00</p>
                                <p>Jeudi: 9:00 - 20:00</p>
                                <p>Vendredi: 9:00 - 20:00</p>
                                <p>Samedi: 9:00 - 20:00</p>
                                <p>Dimanche: 11:00 - 15:00</p>
                                <p>Tél: <a href="tel:+22898300000">+228 98 30 00 00</a></p>
                                <p><a href='mailto:contact@example.com'>contact@mee-market.com</a></p>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="single-widget">
                                <h2>Nos catégories</h2>
                                <ul className="nav nav-pills nav-stacked">
                                    <li><a href="#">Maison</a></li>
                                    <li><a href="#">Moteur</a></li>
                                    <li><a href="#">Electrique</a></li>
                                    <li><a href="#">Electronique</a></li>
                                    <li><a href="#">Informatique</a></li>
                                    <li><a href="#">Mode / Vêtements</a></li>
                                    <li><a href="#">Jouet</a></li>
                                    <li><a href="#">Bijou et Montre</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="single-widget">
                                <h2>Politique</h2>
                                <ul className="nav nav-pills nav-stacked">
                                    <li><a href="#">Conditions d'utilisation</a></li>
                                    <li><a href="#">Politique de confidentialité</a></li>
                                    <li><a href="#">Politique de remboursement</a></li>
                                    <li><a href="#">Le système de facturation</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="single-widget">
                                <h2>A Propos de nous</h2>
                                <ul className="nav nav-pills nav-stacked">
                                    <li><a href="#">Informations sur la société</a></li>
                                    <li><a href="#">Carrières</a></li>
                                    <li><a href="#">Emplacement du magasin</a></li>
                                    <li><a href="#">Droits d'auteur</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3 col-sm-offset-1">
                            <div className="single-widget">
                                <h2>Rester en contact avec nous</h2>
                                <form action="#" className="searchform">
                                    <p>Obtenez les mises à jour les plus récentes  <br />de notre site et être mis à jour vous-même...</p>
                                    <input type="text" placeholder="Votre addresse email" />
                                    <button type="submit" className="btn btn-default"><i className="fa fa-arrow-circle-o-right" /></button>
                                    <p>Et obtenez 20 % de rabais pour votre première commande</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p className="pull-left">Copyright © 2023 MEE MARKET. Tous droits réservés.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
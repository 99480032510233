import { useRouteError } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import { useState } from "react";

export default function Header(props: any) {
    const [isNavOpen, setIsNavOpen] = useState(true);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };
    return (
        <header id="header">{/*header*/}
            <div className="header_top">{/*header_top*/}

            </div>{/*/header_top*/}
            <div className="header-middle">{/*header-middle*/}
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="logo pull-left">
                                <a href="/">Mee Market</a>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="shop-menu pull-right">
                                <ul className="nav navbar-nav">
                                    <li><a href="account"><i className="fa fa-user" /> Mon compte</a></li>
                                    <li><a href="#"><i className="fa fa-star" /> Liste de souhaits</a></li>
                                    <li><a href="checkout"><i className="fa fa-crosshairs" /> Vérifier</a></li>
                                    <li><a href="cart"><i className="fa fa-shopping-cart" /> Panier  <Badge style={{ backgroundColor: 'red' }} bg="danger">11</Badge></a></li>
                                    <li><a href="login_signup"><i className="fa fa-lock" /> Connexion/ Inscription</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle" style={{ backgroundColor: 'black' }} onClick={toggleNav}>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar" />
                                    <span className="icon-bar" />
                                    <span className="icon-bar" />
                                </button>
                            </div>
                            <div className="mainmenu pull-left">
                                <ul className={` ${isNavOpen ? 'nav navbar-nav navbar-collapse in' : 'nav navbar-nav navbar-collapse collapse'}`} style={{ width: 'auto', height: 'auto' }}>
                                    <li><a href="/" className="active">Accueil</a></li>
                                    <li><a href="404">Meilleurs ventes</a></li>
                                    <li className="dropdown"><a href="#">Boutique<i className="fa fa-angle-down" /></a>
                                        <ul role="menu" className="sub-menu">
                                            <li><a href="shop.html">Rechercher par catégorie</a></li>
                                            <li><a href="shop">Produits</a></li>
                                            <li><a href="checkout">Vérifier une commande</a></li>
                                            <li><a href="cart">Panier</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a href="#">Support<i className="fa fa-angle-down" /></a>
                                        <ul role="menu" className="sub-menu">
                                            <li><a href="blog.html">Service client 24H/7J</a></li>
                                            <li><a href="blog-single.html">Faire une réclamation</a></li>
                                            <li><a href="blog-single.html">Donner votre avis</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="404">A propos</a></li>
                                    <li><a href="contact-us">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="search_box pull-right">
                                <input type="text" placeholder="Rechercher un article" />
                                <button type="submit" className="btn btn-default" style={{ backgroundColor: '#FD8D27' }}><i className="fa fa-arrow-circle-o-right" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
import { createBrowserRouter } from 'react-router-dom';

import ErrorPage from '../pages/ErrorPage';
import Root from '../pages/Root';
import LoginSignUp from '../pages/Login_SignUp';
import Cart from '../pages/Cart';
import Checkout from '../pages/checkout';
import ProductDetails from '../pages/ProductDetails';
import ContactUs from '../pages/ContactUs';
import Shop from '../pages/Shop';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'login_signup',
        element: <LoginSignUp />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'cart',
        element: <Cart />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'checkout',
        element: <Checkout />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'ProductDetails',
        element: <ProductDetails />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'Contact-us',
        element: <ContactUs />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'Shop',
        element: <Shop />,
        errorElement: <ErrorPage />,
        children: []
    }
]
);